.input {
	border: 1px solid $lightGray2;
	//align-self: flex-start;
	//padding: $paddingNormal;
	font-weight: $weightMedium;
	color: $gray;

	&:focus {
		border: 1px solid $lightBlue;
	}
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
}


.input-file{
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
  }

  .label-input-file{
	overflow:     hidden;
	width: auto;
	background: $primary;
	border-radius: 5px;
    font-weight: 600;
	color: white;
	  padding: .5rem;
	  margin-left: 1rem;  

	  &:hover{
		  cursor: pointer;
		  background-color: rgba($primary, 0.8);
	  }
}
// COLORS
$overlay: rgba(0, 0, 0, 0.2);
$cancel: #ea4235;
$cancelLight: #eb5b51;
$hoverBtnClose: #6c757d86;
$background: #ffffff;

// ESTILOS GENERALES
@mixin button-action {
	width: 30%;
	padding: 6px 10px;
	margin-right: 8px;
	font-size: 1rem;
	font-weight: 500;
	color: white;
	transition: all 300ms ease-in-out;
	i {
		font-size: 1rem;
	}

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.4);
		-webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
		-moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
		transition: all 0.4s ease 0s;
	}
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}
// TAMAÑO MODAL
.dinamic-modal-mini {
	width: 35%;
}

.dinamic-modal-small {
	width: 45%;
}

.dinamic-modal-normal {
	width: 50%;
}

.dinamic-modal-big {
	width: 57%;
}
.dinamic-modal-super-big {
	width: 80%;
}
// ESTILOS CUERPO MODAL

.dinamic-modal-container {
	top: 50%;
	left: 50%;
	outline: none;
	max-height: 100%;
	//overflow-y: scroll;
	position: absolute;
	border-radius: 4px;
	//padding: $paddingMedium;
	background-color: $white;
	transition: max-height 1.5s ease-in-out;
	transform: translate(-50%, -50%);
	z-index: 2;

	.title {
		text-align: left;
	}

	.modal-container {
		width: 95%;
	}

	.dinamic-header {
		padding: 8px 0px 8px 0px;
		border-radius: 4px 4px 0 0;
		background-color: $primary;
		width: 100%;
		height: 40px;
		position: relative;
		.title {
			color: $white;
			font-weight: 600;
		}

		.btn-modal-close {
			position: absolute;
			right: 2.3%;
			top: 19%;
			height: 20px;
			width: 20px;
			border-radius: 50%;
			background-color: $primary;
			color: $white;
			font-size: 1.4rem;
			transition: background-color 400ms ease-in-out;

			&:hover {
				//color: $primary;
				background-color: $hoverBtnClose;
			}
		}
	}

	.dinamic-body {
		height: auto;
		max-height: 750px;
		overflow-y: scroll;
	}

	.dinamic-footer {
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.btn-action-success {
			@include button-action;
			background-color: $primary;

			&:hover {
				background-color: $secondary;
			}
		}

		.btn-action-cancel {
			@include button-action;
			background-color: $cancel;

			&:hover {
				background-color: $cancelLight;
			}
		}
	}
	.itinerary {
		width: 65%;
	}
	.schedule {
		width: 35%;
		padding-left: 16px;
		padding-top: 8px;
		.title {
			display: flex;
			align-content: flex-end;
			justify-content: space-between;
			.textFilter {
				box-shadow: 0px 2px 14px 0px rgba(234, 84, 95, 0.52);
				box-shadow: 2px 1px 5px #aeaeae;
			}
			.clear {
				height: auto;
			}
		}
		.check {
			padding-left: 16px;
		}
		.columns-ininerary {
			// display: 		flex;
			// flex-wrap: 		wrap;
			// height:			400px;
			// overflow-y: 	auto;
			padding-right: 0px;
			margin-right: 0px;
			// padding-bottom: 0px;
			.item {
				display: flex;
				justify-content: space-between;
				align-content: center;
				width: 100%;
				padding-right: 0px;
				margin-bottom: 0px;
				margin-right: 0px;
				margin-bottom: 8px;
				// height: 30px;
				.check {
					align-self: center;
				}
				&:hover {
					background: #ffebeb;
					cursor: pointer;
				}
			}
			.item:nth-child(odd) {
				// background-color:red;
			}
		}
	}

	.container-side-form {
		width: 90%;
	}
}

.overlay {
	top: 0;
	left: 0;
	z-index: 3;
	width: 100%;
	height: 100%;
	position: fixed;
	transition: height 1500ms ease-in-out;
	background-color: $overlay;
	::-webkit-scrollbar {
		width: 6px;
	}
	::-webkit-scrollbar-thumb {
		background-color: #cccccc;
	}
}

.show-dinamic-modal {
	opacity: 1;
	visibility: visible;
	transform: scale(1);
	transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.hide-dinamic-modal {
	opacity: 0;
	visibility: hidden;
	transform: scale(1.1);
	transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

/*    LARGE DEVICES    */

@media screen and (min-width: 1440px) {
}

/*    TABLETS DEVICES    */

@media screen and (max-width: 768px) {
	.dinamic-modal-mini {
		width: 85%;
	}
	.dinamic-modal-small {
		width: 85%;
	}

	.dinamic-modal-normal {
		width: 85%;
	}

	.dinamic-modal-big {
		width: 85%;
		max-height: 85%;
	}
}



.image-zoom-modal {
	display: flex;
	position: fixed;
	z-index: 100;
	/*padding-top: 10px; */
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	/*background-color: rgb(0,0,0); */
	background-color: rgba(119, 117, 117, 0.582);
}

.hide-zoom-modal {
	opacity: 0;
	visibility: hidden;
	transform: scale(1.1);
	transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.show-zoom-modal {
	opacity: 1;
	visibility: visible;
	transform: scale(1);
	transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
	z-index: 1;
}

.modal-content-zoom {
	background-color: #efefde00;
	margin: 85px;
	border-radius: 5px;
	padding-right: 20px;
	padding-left: 20px;
	width: 40%;
	max-height: 80%;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}

.img-zoom {
	max-height: 60vh;
}

#modal-close-zoom {
	background-color: #efefde00;
	color: white;
	font-size: $fontTriple;
	font-weight: 900;
	cursor: pointer;
}


/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
	.dinamic-modal-container {
		.container-side-form {
			width: 100%;
		}
	}
}

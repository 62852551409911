

    p{
        font-size: .93rem;
        line-height: 22px;
        color: #000;
		padding-left: 25px;
		font-weight: 600;

    }
    
    .input {
        color: $gray;
        //padding: $paddingNormal ;
        border-bottom: 2px solid rgba($gray, 0.5);
        &:focus { border-bottom: 2px solid $primary; }
    }

	.space {
		margin-left: $marginMedium;
	}

	.label-gray {
		background-color: #eaeaea;
    	padding: 2px 16px;
	}

	.text-area-gray {
		background-color: #eaeaea;
		padding: 8px;
		width: 100%;
		font-size: $fontText;
		//min-height: 100px;
	}

/* Estilos label para filas con un solo input */

	.label-normal { 
		width: 20%;
    }
    .label-small { 
		width: 25%;
	}

	.label-extra { 
		width: 31%;
	}

	.label-medium { 
		width: 35%;
	}

	.label-big { 
		width: 45%;
    }
    
    .label-medium-row { 
		width: 42%;
	}

/* Estilos label para filas con dos inputs */
	.label-duo-huge {
		width: 115%;
	}

	.label-duo-big {
		width: 90%;
	}

	.label-duo-normal {
		width: 53%;
	}

    .label-duo-small {
        width: 40%;
        margin-right: 16px;
    }
    
/* Estilos label para filas con tres inputs */

	.label-triple-huge {
		width: 130%;
	}




/*    LARGE DEVICES    */

@media screen and (min-width: 1440px) {


}

/*    TABLETS DEVICES    */

@media screen and (max-width: 768px) {    
    
    .input{
        margin-top: 16px;
    }
    
}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {


}
.sidebar {
	height: 100%;
	width: 200px ;
	position: sticky;
	height: 100vh;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: $lightGray;
	overflow-x: hidden;
	transition: 300ms;

	.item {
		background-color: $lightGray;

		cursor: pointer;
		min-width: 270px;
		width: 100%;
		position: relative;
		padding: 10px 16px;
		border: none;
		text-align: left;
		outline: none;
		transition: background-color 0.3s ease-in-out;

		i {
			top: 12px;
		}

		a {
			color: $gray;
		}
	}

	.item:hover {
		background-color: $primary;
		a {
			color: $white;
		}
		i {
			color: $white;
		}
		span {
			color: $white;
		}
	}

	.panel {
		background-color: $lightGray;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s ease-out;
	}

	.content-side {
		width: 100%;
		.nav-item {
			padding-left: $paddingMedium;
			padding: 0px 32px;
			transition: 300ms;

			&:hover {
				background-color: #6c757d8a;
			}
		}

		.active-item {
			background-color: #6c757d8a;
		}
	}
}

#content {
	transition: margin-left 300ms;
	//width: 100%;
}

.maxHeight {
	max-height: 136px !important;
}

/*LEFT SIDEBAR*/
.rightsidebar {
	position: fixed;
	right: 0px;
	background-color: $gray;
	height: 100%;
	width: 0px;
	overflow-x: hidden;
	transition: 300ms;
	z-index: 1;
	padding-top: 100px;

	&.open {
		width: 220px;
		padding-left: 10px;
	}
}

/*LEFT SIDEBAR*/
.leftsidebar {
	position: fixed;
	right: 0px;
	background-color: $gray;
	height: 100%;
	width: 0px;
	overflow-x: hidden;
	transition: 300ms;
	z-index: 1;
	padding-top: 100px;

	&.open {
		width: 220px;
		padding-left: 10px;
	}
}

.nav-exit:hover {
	color: $white !important;
}




/*    LARGE DEVICES    */

@media screen and (min-width: 1440px) {

}

/*    TABLETS DEVICES    */

@media screen and (max-width: 768px) {    
    

    
}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
    .sidebar {
		position: fixed;
        padding-top: 70px;
        width: 0px;

        &.open {
            width: 50%;
        }
    }
}
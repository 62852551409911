

.btn {
    cursor: pointer;
    font-size: $fontSmall;
    font-weight: $weightBold;
    background-color: transparent;
    transition: all 200ms ease-in-out;
    &:active { transform: scale(0.95); }
}

.btn-primary {
    border-radius: 5px;
    background-color: $primary;
    &:hover { background-color: rgba($primary, 0.8); }
}

.btn-transparent{
    background-color: transparent !important;
}

.btn-small
{
    width: 40%;
}

.btn-mini
{
    width: 20%;
}

.btn-login{
    width: 70%;
}




/*    LARGE DEVICES    */

@media screen and (min-width: 1440px) {



}

/*    TABLETS DEVICES    */

@media screen and (max-width: 768px) {    
    

}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
    .btn-small
    {
        width: 100%;
    }
    
    .btn-mini
    {
        width: 100%;
    }

}
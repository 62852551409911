/**
*   colors.scss
*   @description: Variables de colores
*/

$primary: #0061b6;
$secondary: #059ed8;
$success: #03a74e;
$info: #17a2b8;
$warning: #ffc107;
$danger: #ea4235;

$black: #000000;
$white: #ffffff;
$gray: #666;
$darkgray: #4e4e4e;
$lightGray: #f5f5f5;
$lightBlue: #51aecf;
$lightGray2: #e6e6e6;

$yellow: #fff159;


/* Classes de colores */

.color-white {
	color: $white;
}

.color-black {
	color: $black;
}

.color-gray {
	color: $gray;
}

.color-darkgray {
	color: $darkgray;
}

.color-primary {
	color: $primary;
}

.color-secondary {
	color: $secondary;
}

.color-success {
	color: $success;
}

.color-info {
	color: $info;
}

.color-warning {
	color: $warning;
}

.color-danger {
	color: $danger;
}

.lightGray {
	color: $lightGray;
}

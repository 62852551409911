.card{
    border-radius: 10px;
    padding: 5px 5px 5px 5px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 1px 0 20px rgba(0,0,0,.05);
}


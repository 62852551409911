.profile {
	.card {

		padding: 20px;
		background-color: $white;
		height: 137px;
	}

	.profile-card {
		border-radius: 4px;
		padding: $paddingMedium;
		background-color: $white;
		box-shadow: 1px 0 20px rgba(0, 0, 0, 0.05);
		::-webkit-scrollbar {
			width: 4px;
		}
		::-webkit-scrollbar-thumb {
			background-color: #cccccc;
		}
	}
}

.Login {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(49, 48, 48, 0.328)),
		url("../../image/store-bg.jpg");
	height: 100vh;

	.card{
			max-width: 400px;
			margin: 15px;
			padding: 20px;
			background-color: $lightGray;
	}
	
}

@media screen and (max-width: 768px) {
	.Login {
		.card{
			max-width: 600px;
		}
	}
}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
	.Login {
		.card{
			max-width: 100%;
		}

	}
}
